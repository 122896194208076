import React from 'react';

const FooterLogo = ({ ...args }) => (
	<svg
		{...args}
		id="YU_logo"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 411.65 272.559"
	>
		<g id="Group_152" data-name="Group 152">
			<path id="Path_162" data-name="Path 162" d="M99.249,164.135l.012,104.511h58.364l.012-104.511L63.941,0H0Z" />
			<path id="Path_163" data-name="Path 163" d="M129.412,0,66.054,110.989H126.24L193.354,0Z" transform="translate(61.246)"/>
		</g>
		<g id="Group_153" data-name="Group 153" transform="translate(217.827 110.868)">
			<path id="Path_164" data-name="Path 164" d="M253.493,119.064V57.528l53.357.121v61.414c0,61.031-45.37,100.155-96.549,100.155-51.541,0-97.274-39.124-97.274-100.155V57.528h54.081v61.536c0,27.777,20.689,46.164,43.193,46.164C232.443,165.228,253.493,146.841,253.493,119.064Z" transform="translate(-113.027 -57.528)" />
		</g>
	</svg>
);
export default FooterLogo;
