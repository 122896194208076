import React from 'react';
import { IComponent, ISideCaseMedia } from '../types/blok';
import Picture from './picture';
import Video from './video';
import { useDesktopView } from '../hooks/use-desktop-view';

export const SideCaseMedia = ({ blok: { media, left_side_image}}: IComponent<ISideCaseMedia>) => {
	const { isDesktop } = useDesktopView();

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1576, width: 1133, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1131, width: 814, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 964, width: 693, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 746, width: 537, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 508, width: 365, media: '(min-width: 768px)' },
		{ alias: 'tablet', height: 1067, width: 767, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 891, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 668, width: 480, media: '(max-width: 480px)' },
	];

	return (
		<div  className={`side-case-media ${left_side_image ? 'side-case-media--left' : 'side-case-media--right'}`}>	
			<div className='side-case-media__image-wrapper'>
				{isDesktop && 
					(media[0].video_desktop?.filename ? <Video className='side-case-media__video' video={media[0].video_desktop} poster={media[0].desktop_image?.filename}/>
						: <Picture className='side-case-media__image' image={media[0].desktop_image} queries={queries}/>)}
				{!isDesktop && 
					(media[0].video_mobile?.filename ? <Video className='side-case-media__video' video={media[0].video_mobile} poster={media[0].mobile_image?.filename}/>
						: <Picture className='side-case-media__image' image={media[0].mobile_image} queries={queries} />)}	
			</div>
		</div>
	);
};
