import React from 'react';
import { useMemo } from 'react';
import { IComponent, ITextBanner } from '../types/blok';
import { richTextResolver } from '../utilis/rich-text-resolver';
import { htmlRaw } from '../utilis/utilis';

export const TextBanner = ({ blok: { body, title,front_page }}: IComponent<ITextBanner>) => {
	const html = useMemo(() => richTextResolver(body), []);
	return (
		<div className={`text-banner ${front_page ? 'text-banner__front-page' : ''}`}>
			<div>{title}</div>
			<div className='text-banner__content' dangerouslySetInnerHTML={htmlRaw(html)}></div>
		</div>
	);
};
