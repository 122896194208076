import React from 'react';
import { IComponent, IFullSizeMediaBanner } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import Video  from './video';

export const FullSizeMediaBanner = ({ blok: { video_mobile, video_desktop,desktop_image,mobile_image,tight_margins }}: IComponent<IFullSizeMediaBanner>) => {
	const { isDesktop } = useDesktopView();

	return (
		<div className={`full-size-media-banner ${tight_margins ? 'tight-margin' : '' }`}>
			<div className='full-size-media-banner__media'>
				{isDesktop && 
					(video_desktop?.filename ? <Video className='full-size-media-banner__video' video={video_desktop} poster={desktop_image?.filename}/>
						: <img className='full-size-media-banner__img' src={desktop_image.filename}/>)}
				{!isDesktop && 
					(video_mobile?.filename ? <Video className='full-size-media-banner__video' video={video_mobile} poster={mobile_image?.filename}/>
						: <img className='full-size-media-banner__img' src={mobile_image.filename}/>)}	
			</div>	
		</div>
	);
};
