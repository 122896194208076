
import React, { useRef, useEffect, useState } from 'react';
import { useIntersectionObserver } from '../hooks/use-intersection-observer';

type VideoWithControlsProps = {
	url?: string,
	className?:string,
	poster?: string,
}

const VideoWithControls = ({ url, className = '', poster }: VideoWithControlsProps) => {
	if (!url ) { return <></>; }
	const ref = useRef<HTMLVideoElement>(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);
	const [ videoIsLoaded, setVideoIsLoaded ] = useState(false);
	const [ initialLoad, setInitialLoad ] = useState(false);
	const [ isVideoPlaying, setIsVideoPlaying ] = useState(false);

	const loadHandler = () => {
		setVideoIsLoaded(true);
	};

	const errorHandler = () => {
		if(!ref.current) return;
		console.error(`Video ${ref.current.src} could not be loaded.`);
		ref.current.classList.add('missing-video');
	};

	const lazyLoadVideo = () => {
		if(ref.current) {
			ref.current.querySelectorAll('source').forEach((source:HTMLSourceElement) => {
				source.src = source.dataset.src;
			});
			ref.current.load();
		}
	};

	useEffect(() => {
		if(ref.current) {
			ref.current.addEventListener('load', loadHandler);
			ref.current.addEventListener('error', errorHandler);

			return () => {
				if(ref.current) {
					ref.current.removeEventListener('load', loadHandler);
					ref.current.removeEventListener('error', errorHandler);
				}
			};
		}
	}, [ref.current]);
	
	useEffect(() => {
		if(!ref.current) return;
		if(initialLoad && videoIsLoaded) {
			const vid = ref.current.querySelector('video');
			vid.src = url;
		}
	}, [url]);

	useEffect(() => {
		if(isIntersecting && !initialLoad) {
			lazyLoadVideo();
			setInitialLoad(true);
		}
		if (!isIntersecting && isVideoPlaying) {
			togglePlayHandler();
		}
		
	}, [isIntersecting]);

	const togglePlayHandler = () => {
		if(!ref.current) return;
		isVideoPlaying ? ref.current.pause() : ref.current.play();
		setIsVideoPlaying(!isVideoPlaying);
	};

	return (
		<>
			<div className={`video-with-controls__wrapper ${isVideoPlaying ? 'playing' : 'paused'}`} onClick={togglePlayHandler}>
				<div className='video-with-controls__controls'>
					{!isVideoPlaying && <div className='video-with-controls__text'>Play</div>}
					<div className='video-with-controls__element'></div>
					<div className='video-with-controls__element'></div>
				</div>
			</div>
			<video ref={ref} className={`video-with-controls ${videoIsLoaded ? 'video-with-controls--loaded' : '' } ${className}`} playsInline loop poster={poster}>
				<source data-src={url} type="video/mp4" />
			</video>
		</>
	);
};
export default VideoWithControls;
