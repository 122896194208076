import React, { useEffect, useState, useRef } from 'react';
import Picture from './picture';
import Video from './video';
import { IHeroCase, IComponent } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';

export  const HeroCase = ({blok: {case_link, desktop_image,mobile_image, client, solution, description, title, subtitle, video_mobile, video_desktop, scroll_caption }}:  IComponent<IHeroCase>) => {
	const [containerHeight, setContainerHeight] = useState('100vh');
	const [contentLeft, setContentLeft] = useState('0');
	const [headingMargin, setHeadingMargin] = useState('0');
	const [isVisible, setIsvisible] = useState(false);
	const { isDesktop } = useDesktopView();
	const wrapperRef = useRef<HTMLDivElement>(null);

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1057, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 906, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 714, width: 1367, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 635, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 635, width: 770, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 635, width: 642, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 635, width: 480, media: '(max-width: 480px)' },
	];

	const [scrollPosition, setScrollPosition] = useState(0);
	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			setScrollPosition(currentScrollY);

			if (currentScrollY > 0) {
				setContainerHeight('0');
				setContentLeft('-600px');
				setHeadingMargin('300px');
			} else {
				if (containerHeight == '0') {
					setContainerHeight('100vh');
					setContentLeft('0');
					setHeadingMargin('0');
					window.scrollTo(0, 0);
				}
			}
		};
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scrollPosition]);

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: .01,
	};

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		setIsvisible(entries.some(el => el.isIntersecting));
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, options);
		if(wrapperRef.current) {
			observer.observe(wrapperRef.current);
		}

		return () => { 
			if(wrapperRef.current) {
				observer.unobserve(wrapperRef.current);
			}
		};
	}, [wrapperRef]);

	const clickHandler = () => {
		setContainerHeight('0');
		setContentLeft('-600px');
		setHeadingMargin('300px');
		window.scrollTo(0, 1);
	};

	return (
		<div className='hero-case__wrapper' ref={wrapperRef}  style={{ opacity: isVisible ? 1 : 0 }}>
			<div className={`hero-case ${containerHeight === '0' ? 'hide' : ''}`} style={{ height: containerHeight }}>
				<div className='hero-case__heading' >
					<div className='hero-case__headline-elements'
						style={{marginTop: headingMargin, marginBottom: `-${headingMargin}` }}>
						<div className='hero-case__headline'>
							<h1 className='hero-case__title'>{title}</h1>
						</div>
						{ case_link[0]?.caption && 
							<div className='hero-case__link-section'>
								<a href={case_link[0]?.url.url} target='_blank' rel='noreferrer'>
									<div className='hero-case__link'>
										{case_link[0]?.caption}
									</div>
								</a>
							</div> }
					</div>
				</div><></>
				<div className='hero-case__overlay-wrapper' onClick={clickHandler}>
					<div className='hero-case__overlay'></div>
					<div className='hero-case__content' 
						style={{ left: contentLeft }}>
						<div className='hero-case__subtitle'>{subtitle}</div>
						<div className='hero-case__elements'>
							<div className='hero-case__client'>{client}</div>
							<div className='hero-case__solution'>{solution}</div>
						</div>
						<div className='hero-case__description'>{description}</div>				
					</div>
					<span className='hero-case__scroll-caption'>
						{scroll_caption}
					</span>	
				</div>
			</div>
			<div className='hero-case__media' style={{ position: isVisible ? 'fixed' : 'initial' }}>
				{isDesktop && (video_desktop?.filename ? <div> 
					<Video className='hero-case__video' video={video_desktop} poster={desktop_image?.filename}/>
				</div> : <Picture className='hero-case__picture' image={desktop_image} queries={queries}/>)}
				{!isDesktop && 
					(video_mobile?.filename ? <Video className='hero-case__video' video={video_mobile} poster={mobile_image?.filename}/>
						: <Picture className='hero-case__picture' image={mobile_image} queries={queries}/>)}		
			</div>
		</div>
	);
};
export default HeroCase;
