import React from 'react';
import FooterLogo from '../components/svg/footer-logo';
import { IFooter, IComponent, IColorPicker } from '../types/blok';
import { Transition } from './transition';

const bgColor: IColorPicker = {color: 'var(--background)'};

export const Footer = ({blok: {email, phone, address, city }}: IComponent<IFooter>) => {
	return (
		<footer className='footer'>	
			<div className='footer__contact'>
				<div className='footer__email-phone'>
					<a href={`mailto:${email}`} className='footer__email'>{email}</a>
					<a href={`tel:${phone}`}>{phone}</a>
				</div>
				<div className='footer__address-city'>
					<span className='footer__address'>{address}</span>
					<span>{city}</span>
				</div>
			</div>	
			<FooterLogo className='footer__logo'/>
			<Transition transition_color={bgColor} url=''><></></Transition>
		</footer>
	);
};
