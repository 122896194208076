import React from 'react';
import clsx from 'clsx';
import { IComponent, IMultiMediaColumns } from '../types/blok';
import Picture from './picture';
import Video from './video';
import { useDesktopView } from '../hooks/use-desktop-view';

export const MultiMediaColumns = ({ blok: { media, offset, object_fit }}: IComponent<IMultiMediaColumns>) => {
	const { isDesktop } = useDesktopView();

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1349, width: 1063, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 944, width: 743, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 792, width: 623, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 594, width: 467, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 440, width: 345, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 926, width: 732, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 765, width: 604, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 562, width: 444, media: '(max-width: 480px)' },
	];
	return (
		<div className='multi-media-columns'>
			{isDesktop && <>
				<div className={`${offset ? 'multi-media-columns__media-left-offset': 'multi-media-columns__media-left'}`}>
					{media[0].video_desktop?.filename ? <Video className={clsx('multi-media-columns__media-left-offset__video', object_fit === 'contain' && 'multi-media-columns__media-left-offset__video--contain')} video={media[0].video_desktop} poster={media[0]?.desktop_image?.filename} />
						: <Picture className='multi-media-columns__media-left-offset__image' image={media[0]?.desktop_image} queries={queries}/>}
				</div>
				<div className={`${offset ? 'multi-media-columns__media-right-offset': 'multi-media-columns__media-right'}`}>
					{media[1].video_desktop?.filename ?<Video className={clsx('multi-media-columns__media-right-offset__video', object_fit === 'contain' && 'multi-media-columns__media-right-offset__video--contain')} video={media[1].video_desktop} poster={media[1]?.desktop_image?.filename}/>
						: <Picture className='multi-media-columns__media-right-offset__image' image={media[1]?.desktop_image} queries={queries}/>}
				</div>
			</> }

			{!isDesktop && <>
				<div className={`${offset ? 'multi-media-columns__media-left-offset': 'multi-media-columns__media-left'}`}>
					{media[0].video_mobile?.filename ? <Video className='multi-media-columns__media-left-offset__video' video={media[0].video_mobile} poster={media[0]?.mobile_image?.filename}/>
						: <Picture className='multi-media-columns__media-left-offset__image' image={media[0]?.mobile_image} queries={queries}/>}
				</div>
				<div className={`${offset ? 'multi-media-columns__media-right-offset': 'multi-media-columns__media-right'}`}>
					{media[1].video_mobile?.filename ?<Video className={clsx('multi-media-columns__media-right-offset__video')} video={media[1].video_mobile} poster={media[1]?.mobile_image?.filename}/>
						: <Picture className='multi-media-columns__media-right-offset__image' image={media[1]?.mobile_image} queries={queries}/>}
				</div>
			</> }	
		</div>
	);
};
