import React from 'react';
import { IComponent, IFullWidthMediaBanner } from '../types/blok';
import Picture from './picture';
import Video from './video';
import { useDesktopView } from '../hooks/use-desktop-view';

export const FullWidthMediaBanner = ({ blok: { video_mobile, video_desktop,desktop_image,mobile_image }}: IComponent<IFullWidthMediaBanner>) => {
	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1328, width: 2338, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 965, width: 1698, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 829, width: 1458, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 662, width: 1145, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 458, width: 802, media: '(min-width: 768px)' },
		{ alias: 'tablet', height: 423, width: 740, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 351, width: 614, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 260, width: 452, media: '(max-width: 480px)' },
	];

	return (
		<div className='full-width-media-banner'>
			<div className='full-width-media-banner__media'>
				{isDesktop && 
					(video_desktop?.filename ? <Video className='full-width-media-banner__video' video={video_desktop} poster={desktop_image?.filename}/>
						: <Picture className='full-width-media-banner__picture' image={desktop_image} queries={queries}/>)}
				{!isDesktop && 
					(video_mobile?.filename ? <Video className='full-width-media-banner__video' video={video_mobile} poster={mobile_image?.filename}/>
						: <Picture className='full-width-media-banner__picture' image={mobile_image} queries={queries} />)}	
			</div>	
		</div>
	);
};
