import React, { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Picture from './picture';
import VideoWithControls from './video-with-controls';
import Video from './video';
import { IHero, IComponent } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import { richTextResolver } from '../utilis/rich-text-resolver';
import { htmlRaw } from '../utilis/utilis';
import VimeoVideo from './vimeo_video';

export  const Hero = ({blok: { vimeo_video, enable_video_controls, desktop_image, mobile_image, title, video_mobile, video_desktop,scroll_caption, parallax, crop_image }}:  IComponent<IHero>) => {
	const { isDesktop } = useDesktopView();
	const [isVisible, setIsvisible] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: .01,
	};
	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		setIsvisible(entries.some(el => el.isIntersecting));
	};
	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, options);
		if(containerRef.current) {
			observer.observe(containerRef.current);
		}
		return () => { 
			if(containerRef.current) {
				observer.unobserve(containerRef.current);
			}
		};
	}, [containerRef]);

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1607, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1205, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 1054, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 858, width: 1367, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 642, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 1535, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 1283, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 960, width: 480, media: '(max-width: 480px)' },
	];

	const html = useMemo(() => richTextResolver(title), []);

	const getVideoType = () => {
		if(video_desktop?.filename) {
			return { type: 'video', hasControls: enable_video_controls, video: video_desktop };

		}
		if (vimeo_video) {
			return { type: 'vimeo', hasControls: enable_video_controls, video: vimeo_video };
		}

		return { type:'none', hasControls: false, video: '' };
	};

	const videoType = getVideoType();

	return (
		<div className={`hero ${parallax ? 'hero--parallax' : ''} 
			${enable_video_controls && ((isDesktop && (video_desktop?.filename || vimeo_video)) || (!isDesktop && (video_mobile?.filename || vimeo_video))) ? '' : 'controls-disabled'}`} ref={containerRef} style={isVisible ? { opacity: 1 } : { opacity: 0, zIndex: -1 }}>
			<div className={`hero__container ${crop_image ? 'crop-image' : ''} `}>
				{isDesktop && (
					<div className={clsx('hero__wrapper', { 'controls-disabled': !enable_video_controls })}>
						{enable_video_controls && videoType.type === 'video' ?
							<VideoWithControls
								className={clsx('hero__media', {'hero__media--parallax' : parallax})}
								url={video_desktop?.filename}
							/>
							:
							<Video
								className={clsx('hero__media', {'hero__media--parallax' : parallax})}
								video={videoType.video}
							/>
						}
						{!enable_video_controls && videoType.type === 'vimeo' && (
							<VimeoVideo className={clsx('hero__media hero__video hero__media--vimeo', { 'hero__media--parallax': parallax })} videoClasses='hero__media--vimeo-video' url={videoType.video as string} />
						)}
						{videoType.type === 'none' && (
							<Picture className={clsx('hero__media', {'hero__media--parallax' : parallax})}
								image={desktop_image} queries={queries}
							/>
						)}
					</div>
				)}

				{!isDesktop && (
					<div className={clsx('hero__wrapper', { 'controls-disabled': !enable_video_controls })}>
						{enable_video_controls && videoType.type !== 'none' ?
							<VideoWithControls
								className={clsx('hero__media', {'hero__media--parallax' : parallax})}
								url={videoType.video}
							/>
							:
							null
						}
						{!enable_video_controls && videoType.type !== 'none' ?
							<Video
								className={clsx('hero__media', {'hero__media--parallax' : true})}
								video={videoType.video}
							/>
							:
							null
						}
						{!enable_video_controls && videoType.type === 'vimeo' && (
							<VimeoVideo className={clsx('hero__media hero__video hero__media--vimeo', { 'hero__media--parallax': parallax })} videoClasses='hero__media--vimeo-video' url={videoType.video as string} />
						)}
						{videoType.type === 'none' && (
							<Picture className={clsx('hero__media', {'hero__media--parallax' : parallax})}
								image={desktop_image} queries={queries}
							/>
						)}
					</div>
				)}

				{title && <div className={clsx(`hero__content ${!desktop_image.filename && !mobile_image.filename && !video_desktop.filename && !video_mobile.filename && !vimeo_video ? 'text-only' : ''}`,
					{ 'hero__content--inverted': videoType.type === 'video' || videoType.type === 'vimeo' },
				)}>
					<div className='hero__elements'>
						<h1 className={clsx('hero__headline', { 'hero__headline--inverted': videoType.type === 'video' || videoType.type === 'vimeo' })} dangerouslySetInnerHTML={htmlRaw(html)} />
					</div>
					<span className={clsx('hero__scroll-caption',{ 'hero__scroll-caption--inverted': videoType.type === 'video' || videoType.type === 'vimeo' } )}>{scroll_caption}</span>
				</div>}	
			</div>
		</div>
	);
};
export default Hero;
