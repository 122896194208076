import React, { useRef } from 'react';
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import gsap from 'gsap';
import { IColorPicker } from '../types/blok';

type TransitionProps = {
	transition_color?: IColorPicker,
	className?:string,
	url: string,
	children: React.ReactNode,
}

export const Transition = ({ transition_color = { color: '#fffff' }, className = '', url, children }: TransitionProps) => {
	const coverRef = useRef<HTMLDivElement>(null);
	const vertical = ({ node, props: { length: seconds }, transitionDirection }) => {
		const directionTo = transitionDirection === 'up' ? '-100%' : '100%';
		const directionFrom = transitionDirection === 'up' ? '100%' : '-100%';
		const wait = seconds / 6;
		const half = (seconds - wait) / 2;
		return gsap
			.timeline()
			.set(coverRef.current, { y: directionFrom })
			.to(coverRef.current, {
				y: '0%',
				ease: 'power1.easeInOut',
				duration: half,
			})
			.set(node, { opacity: 0 })
			.to(
				coverRef.current,
				{
					y: directionTo,
					ease: 'power1.easeIn',
					duration: half,
				},
				`+=${wait}`,
			);
	};

	return( <div className={className}>
		<TransitionLink
			to={url}
			exit={{
				length: 1,
				trigger: ({ exit, node }) =>
					vertical({
						props: exit,
						node,
						transitionDirection: 'down',
					}),
			}}
			entry={{
				delay: 1 / 2,
			}}
		>
			{children}
		</TransitionLink>

		<TransitionPortal>
			<div
				ref={coverRef}
				className='transition-portal'
				style={{background: transition_color?.color || 'var(--background)'}}
			/>
		</TransitionPortal>
	</div>
	);
};
