import React from 'react';
import SbEditable from 'storyblok-react';
import { Hero } from './hero'; 
import { Header } from './header';
import { TextBanner } from './text-banner';
import { Case } from './case';
import { Cases } from './cases';
import { Grid } from './grid';
import { Footer } from './footer';
import { HeroCase } from './hero-case';
import { TextColumns } from './text-columns';
import  { FullWidthMediaBanner }  from './full-width-media-banner';
import  { MobileCaseColumns }  from './mobile-case-columns';
import  { MultiMediaColumns }  from './multi-media-columns';
import { SideCaseMedia } from './side-case-media';
import { Awards } from './awards';
import { MediaWithText } from './media-with-text';
import { MobileCaseAndMediaColumns }  from './mobile-case-and-media-columns';
import { CaseContact } from './case-contact';
import { Contact } from './contact';
import { TextAndMediaHero } from './text-and-media-hero';
import { FullSizeMediaBanner } from './full-size-media-banner';
import { TextParagraphs } from './text-paragraphs';
import { ImageBanner } from './image-banner';
import { AboutHeading } from './about-heading';
import { HeroCaseLink } from './hero-case-link';

const Components = {
	'hero': Hero,
	'header': Header,
	'text-banner': TextBanner,
	'case': Case,
	'cases': Cases,
	'grid': Grid,
	'footer': Footer,
	'hero-case': HeroCase,
	'text-columns': TextColumns,
	'full-width-media-banner': FullWidthMediaBanner,
	'full-size-media-banner': FullSizeMediaBanner,
	'mobile-case-columns': MobileCaseColumns,
	'multi-media-columns': MultiMediaColumns,
	'side-case-media': SideCaseMedia,
	'case-contact': CaseContact,
	'awards': Awards,
	'media-with-text' : MediaWithText,
	'mobile-case-and-media-columns': MobileCaseAndMediaColumns,
	'contact': Contact,
	'text-and-media-hero' : TextAndMediaHero,
	'text-paragraphs' : TextParagraphs,
	'image-banner' : ImageBanner,
	'about-heading' : AboutHeading,
	'hero-case-link': HeroCaseLink,
};


const DynamicComponent = ({ blok, ...rest }) => {
	if  (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (
			<SbEditable content={blok}>
				<Component blok={blok}{...rest}/>
			</SbEditable>
		);
	}
 
	return <p>{blok.component} not implemented yet.</p>;
};
export default DynamicComponent;
