import React from 'react';
import Picture from './picture';
import Video from './video';
import { IComponent, ICase } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import { Transition } from './transition';

export  const Case = ({blok: {transition_color,link, title, description, body, companyName, desktop_image, mobile_image, video_desktop,video_mobile}}:  IComponent<ICase>) => {
	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 503, width: 1186, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 789, width: 1861, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 687, width: 1621, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 555, width: 1308, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 409, width: 965, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 301, width: 710, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 805, width: 606, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 560, width: 445, media: '(max-width: 480px)' },
	];
	return (
		<Transition className="case" url={link.cached_url} transition_color={transition_color}>
			<h1 className='case__headline'>{title}</h1>
			{isDesktop && (video_desktop?.filename ? <Video className='case__video' video={video_desktop} poster={desktop_image?.filename}/>
				: (desktop_image?.filename && <Picture className='case__case-image' image={desktop_image} queries={queries} />) )
			}
			{!isDesktop && (video_mobile?.filename ? <Video className='case__video' video={video_mobile} poster={mobile_image?.filename}/>
				: (mobile_image?.filename && <Picture className='case__case-image' image={mobile_image} queries={queries}/>) )
			}
			<div className='case__content'>
				<div className='case__elements'>
					<p className='case__elements__description'>{description}</p>
					<p className='case__elements__body'>{body}</p>
					<p className='case__elements__company-name'>{companyName}</p>
				</div>
			</div><></>
		</Transition>
	);
};
export default Case;
