
import React, { useRef, useEffect, useState } from 'react';
import { IMedia } from '../types/blok';
import { useIntersectionObserver } from '../hooks/use-intersection-observer';

type VideoProps = {
	video: IMedia;
	className?:string,
	poster?: string,
}

const Video = ({ video, className = '', poster }: VideoProps) => {
	if (!video || !video?.filename ) { return <></>; }
	const { filename } = video;
	const ref = useRef<HTMLVideoElement>(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);
	const [ videoIsLoaded, setVideoIsLoaded ] = useState(false);
	const [ initialLoad, setInitialLoad ] = useState(false);

	const loadHandler = () => {
		setVideoIsLoaded(true);
	};

	const errorHandler = () => {
		if(!ref.current) return;
		console.error(`Video ${ref.current?.src} could not be loaded.`);
		ref.current.classList.add('missing-video');
	};

	const lazyLoadVideo = () => {
		if(ref.current) {
			ref.current.querySelectorAll('source').forEach((source:HTMLSourceElement) => {
				source.src = source.dataset.src;
			});
			ref.current.load();
		}
	};

	useEffect(() => {
		if(ref.current) {
			ref.current.addEventListener('load', loadHandler);
			ref.current.addEventListener('error', errorHandler);

			return () => {
				if(ref.current) {
					ref.current.removeEventListener('load', loadHandler);
					ref.current.removeEventListener('error', errorHandler);
				}
			};
		}
	}, [ref.current]);
	
	useEffect(() => {
		if(!ref.current) return;
		if(initialLoad && videoIsLoaded) {
			const vid = ref.current.querySelector('video');
			vid.src = video;
		}
	}, [video]);

	useEffect(() => {
		if(isIntersecting && !initialLoad) {
			lazyLoadVideo();
			setInitialLoad(true);
		}
	}, [isIntersecting]);
	
	return (
		<video
			ref={ref}
			className={`video ${videoIsLoaded ? 'video--loaded' : '' } ${className}`}
			autoPlay
			playsInline
			loop
			muted
			poster={poster}
		>
			<source data-src={filename} type="video/mp4" />
		</video>
	);
};

export default Video;
