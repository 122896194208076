import React from 'react';
import { IComponent, ITextParagraphs } from '../types/blok';
import { richTextResolver } from '../utilis/rich-text-resolver';
import { htmlRaw } from '../utilis/utilis';

export const TextParagraphs = ({ blok: { paragraphs }}: IComponent<ITextParagraphs>) => {
	
	return (
		<div className='text-paragraph'>
			<div className='text-paragraph__wrapper'>	
				{paragraphs && paragraphs.map(p => <div className='text-paragraph__content' 
					key={p._uid}>
					<div className='text-paragraph__title'>{p.title}</div>
					<div className='text-paragraph__body' dangerouslySetInnerHTML={htmlRaw(richTextResolver(p.paragraph))}></div> 
				</div>)}
			</div>
		</div>	
	);
};
