import React from 'react';
import { IComponent, IImageBanner } from '../types/blok';
import Picture from './picture';
import { useDesktopView } from '../hooks/use-desktop-view';

export const ImageBanner = ({ blok: { desktop_image, mobile_image }}: IComponent<IImageBanner>) => {

	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1015, width: 1792, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 954, width: 1686, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 818, width: 1446, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 641, width: 1133, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 447, width: 790, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 303, width: 535, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 342, width: 606, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 252, width: 445, media: '(max-width: 480px)' },
	];
	
	return (
		<div className='image-banner'>
			<div className='image-banner__wrapper'>	
				{isDesktop && <Picture className='image-banner__picture' image={desktop_image} queries={queries}/>}
				{!isDesktop && <Picture className='image-banner__picture' image={mobile_image} queries={queries} />}	
			</div>
		</div>	
	);
};
