import React from 'react';
import { IComponent, ICases } from '../types/blok';
import Picture from './picture';
import Video from './video';
import { useDesktopView } from '../hooks/use-desktop-view';
import { Transition } from './transition';

export const Cases = ({ blok: { cases }}: IComponent<ICases>) => {
	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1486, width: 1486, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1102, width: 1102, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 958, width: 958, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 770, width: 770, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 564, width: 564, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 411, width: 411, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 814, width: 616, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 456, width: 340, media: '(max-width: 480px)' },
	];
	return (
		<div className='cases'>
			{cases.map((c, index) => (
				<Transition key={index} transition_color={c.transition_color} url={c.link.cached_url} className='cases__section'>
					<div className='cases__content'>
						{isDesktop && (c.desktop_video?.filename ? <Video className='cases__video' video={c.desktop_video} poster={c.desktop_image?.filename}/>
							: <Picture className='cases__picture' image={c.desktop_image} queries={queries} />)
						}
						{!isDesktop && (c.mobile_video?.filename ? <Video className='cases__video' video={c.mobile_video} poster={c.mobile_image?.filename}/>
							: <Picture className='cases__picture' image={c.mobile_image} queries={queries} />)
						}
					</div>
					<div className='cases__text-wrapper'>
						<h2 className='cases__text-wrapper__headline'>{c.title}</h2>
						<div className='cases__text-wrapper__description'>
							<div className='cases__text-wrapper__item'>{c.description}</div>
							<div>{c.company_name}</div>
						</div>
					</div>
				</Transition>
			))}
		</div>
	);
};
