import React from 'react';
import Layout from '../components/layout';
import Meta from '../components/meta';
import DynamicComponent from '../components/dynamicComponent';
import { useStoryblok } from '../lib/storyblok';
import { Blok } from '../types/blok';

const TemplatePage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);

	const components = story.content.body.map((blok: Blok) => (
		<DynamicComponent key={blok._uid} blok={blok} />
	));

	return (
		<Layout>
			<Meta
				title={story.content.meta?.length > 0 ? story.content.meta[0].title : ''}
				description={story.content.meta?.length > 0 ? story.content.meta[0].description : ''}
				ogImage={story.content.meta?.length > 0 ? story.content.meta[0]?.ogImage?.filename : ''}
			/>
			{components}
		</Layout>
	);
};

export default TemplatePage;
