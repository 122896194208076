import React from 'react';
import Picture from './picture';
import Video from './video';
import { ITextMediaHero, IComponent } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';


export  const TextAndMediaHero = ({blok: { description,video_mobile, video_desktop, mobile_image, desktop_image, scroll_caption}}:  IComponent<ITextMediaHero>) => {
	const { isDesktop } = useDesktopView();

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 554, width: 480, media: '(max-width: 480px)' },
	];
	return (
		
		<div className='text-and-media-hero'>
			<div className='text-and-media-hero__wrapper'>
				<div className='text-and-media-hero__media-container'>
					{isDesktop && 
						(video_desktop?.filename ? <Video className='text-and-media-hero__media-container__video__video-loaded' video={video_desktop} poster={desktop_image?.filename}/>
							: (desktop_image?.filename && <Picture className='text-and-media-hero__media-container__image__image-loaded' image={desktop_image} queries={queries}/>))}
					{!isDesktop && 
						(video_mobile?.filename ? <Video className='text-and-media-hero__media-container__video__video-loaded' video={video_mobile} poster={mobile_image?.filename}/>
							: (mobile_image?.filename && <Picture className='text-and-media-hero__media-container__image__image-loaded' image={mobile_image} queries={queries}/>))}		
				</div>
				<div className='text-and-media-hero__content'>
					<div className='text-and-media-hero__content__elements'>
						<div className='text-and-media-hero__content__elements__description'>{description}</div>
					</div>
					<span className='text-and-media-hero__content__scroll-caption'>{scroll_caption}</span>
				</div><></>
			</div>
		</div>
	);
};
export default TextAndMediaHero;
