import React from 'react';
import { IComponent, IContact } from '../types/blok';

export const CaseContact = ({ blok: { title, telephone, email, description }}: IComponent<IContact>) => {
	
	return (
		<div className='case-contact'>
			<h4 className='case-contact__title'>{title}</h4>
			<div className='case-contact__content'>
				<div className='case-contact__elements'>
					<a href={`mailto:${email}`} className='case-contact__email'>{email}</a>
					<a href={`tel:${telephone}`} className='case-contact__telephone'>{telephone}</a>
				</div>
				<p className='case-contact__description'>{description}</p>
			</div>
		</div>
	);
};
