import React from 'react';
import { IComponent, IMobileCaseColumns } from '../types/blok';
import Picture from './picture';
import Video from './video';
import { useDesktopView } from '../hooks/use-desktop-view';

export const MobileCaseColumns = ({ blok: { media_left, media_right }}: IComponent<IMobileCaseColumns>) => {
	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 2100, width: 975, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1254, width: 585, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 997, width: 465, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 662, width: 309, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 295, width: 137, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 237, width: 109, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 646, width: 301, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 453, width: 212, media: '(max-width: 480px)' },
	];

	return (
		<div className='mobil-case-media-columns'>
			<div className='mobil-case-media-columns__media-left'>
				{isDesktop && 
					(media_left[0].video_desktop?.filename ? <Video className='mobil-case-media-columns__media-left__video' video={media_left[0].video_desktop} poster={media_left[0]?.desktop_image?.fieldtype}/>
						: <Picture className='mobil-case-media-columns__media-left__image' image={media_left[0].desktop_image} queries={queries}/>)}
				{!isDesktop && 
					(media_left[0].video_mobile?.filename ? <Video className='mobil-case-media-columns__media-left__video' video={media_left[0].video_mobile} poster={media_left[0]?.mobile_image?.filename}/>
						:<Picture className='mobil-case-media-columns__media-left__image' image={media_left[0].mobile_image} queries={queries} />)}	
			</div>
			<div className='mobil-case-media-columns__media-right'>
				{isDesktop && 
					(media_right[0].video_desktop?.filename ? <Video className='mobil-case-media-columns__media-right__video' video={media_right[0].video_desktop} poster={media_right[0]?.desktop_image?.filename}/>
						: <Picture className='mobil-case-media-columns__media-right__image' image={media_right[0].desktop_image} queries={queries}/>)}
				{!isDesktop && 
					(media_right[0].video_mobile?.filename ? <Video className='mobil-case-media-columns__media-right__video' video={media_right[0].video_mobile} poster={media_right[0]?.mobile_image?.filename}/>
						:<Picture className='mobil-case-media-columns__media-right__image' image={media_right[0].mobile_image} queries={queries} />)}	
			</div>
		</div>
	);
};
