import React from 'react';
import { IComponent, ITextColumns } from '../types/blok';
import { richTextResolver } from '../utilis/rich-text-resolver';
import { htmlRaw } from '../utilis/utilis';

export const TextColumns = ({ blok: { text_columns, title, right_side_column,reduced_margins }}: IComponent<ITextColumns>) => {
	
	return (
		<div className={`text-column ${reduced_margins ? 'reduced-margins' : ''}`}>
			<h1 className='text-column__title'>{title}</h1>
			<div className='text-column__wrapper'>	
				{text_columns && text_columns.map(text_column => <div className={`text-column__content${right_side_column ? '--right' : ''}`} 
					key={text_column._uid}>
					<div className='text-column__title-content'>{text_column.title}</div>
					<div className='text-column__body' dangerouslySetInnerHTML={htmlRaw(richTextResolver(text_column.body))}></div> 
				</div>)}
			</div>
		</div>	
	);
};
