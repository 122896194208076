import React, { useMemo} from 'react';
import { IComponent, IContent } from '../types/blok';
import { richTextResolver } from '../utilis/rich-text-resolver';
import { htmlRaw } from '../utilis/utilis';

export  const AboutHeading = ({blok: { body}}:  IComponent<IContent>) => {
	const html = useMemo(() => richTextResolver(body), []);
	return (	
		<div className='about-heading'>
			<div className='about-heading__wrapper'>
				<div className='about-heading__content'dangerouslySetInnerHTML={htmlRaw(html)}>
				</div><></>
			</div>
		</div>
	);
};
