import { useEffect, useState } from 'react';
import { useWindowSize } from './use-window-size';


export const useDesktopView = () => {
	const { width } = useWindowSize();
	const [ desktopView, setDesktopView ] = useState(null);

	useEffect(() => {
		if(width) {
			setDesktopView(width > 768);
		}
	}, [width]);

	return { isDesktop: desktopView };
};
