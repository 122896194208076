import React, { useState } from 'react';
import Logo from '../components/svg/logo';
import { IHeader, IComponent } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import { Link } from 'gatsby';

export const Header = ({blok: { linksArray = [], email, phone, address, city } }:  IComponent<IHeader>) => {
	const [ isVisible, setIsVisible ] = useState(false);
	const { isDesktop } = useDesktopView();
	const onToggleMenuHandler = () => {
		if (isVisible) {
			closeMenu();
		} else {
			setIsVisible(true);
		}
	};
	const onLinkClickHandler = () => {
		if (isVisible) {
			closeMenu();
		}
	};

	const closeMenu = () => {
		setIsVisible(false);
	};
	const tagsData = [
		{text: 'All Cases', id :'allCases'},
		{text: 'Experiences', id :'experience'},
		{text: 'Websites', id :'websites'},
		{text: 'Campaigns', id :'campaign'},
		{text: 'Salestool', id :'salestool'},
	];
	return (
		<header className={`header ${isVisible ? 'header--visible' : ''} `}>
			{!isDesktop && <div className='header__mobile'>
				{linksArray?.map(link => (
					<div className='header__mobile__link' key={link._uid} >
						<Link className='' to={link.url?.cached_url} onClick={onLinkClickHandler}>{link.caption}</Link>
						{
							link.caption === 'Work' &&
							<div className='header__mobile__sub-link--container'>
								{tagsData.map(tag => (
									<Link
										key={tag.id}
										id={tag.id}
										className='header__mobile__sub-link'
										to={'/?tag=' + tag.id}
										onClick={onLinkClickHandler}
									>
										{tag.text}
									</Link>
								))}
							</div>
						}
					</div>
				))}
				<div className='header__mobile__contact'>
					<div className='header__mobile__email-phone'>
						<a href={`mailto:${email}`} >{email}</a>
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
					<div className='header__mobile__address-city'>
						<span>{address}</span>
						<span>{city}</span>
					</div>
				</div>
			</div>}	
			<div className='header__inner'>	
				<Link  className='header__logo' to='/'><Logo/></Link>
				<div className='header__links'>
					{!isDesktop &&	<button className='header__burger-menu' onClick={onToggleMenuHandler}>
						<span className='header__burger-menu-item'></span>
						<span className='header__burger-menu-item'></span>
					</button> 
					}
					{isDesktop && <ul className='header__section'>
						{linksArray.map(link => (
							<Link
								key={link._uid}
								to={link.url.url}
								className='header__link'
							>
								{link.caption}
							</Link>
						))}
					</ul>}
				</div>
			</div>
		</header>
	);
};
