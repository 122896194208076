import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { IComponent, IGrid} from '../types/blok';
import CaseThumbnail from './case-thumbnail';

export const Grid = ({blok: { columns } }:  IComponent<IGrid>) => {
	const [selectedTags, setSelectedTags] = useState<string[]>(['All cases']);

	const tags = useMemo<string[]>(() => {
		const column_tags: string[] = [];
	
		for(const column of columns) {
			for(const tag of column.tag) {
				column_tags.push(tag);
			}
		}

		return [...new Set(['All cases', ...column_tags])] as string[];
	}, []);

	const selectTag = (tag: string) => {
		if (tag === 'All cases') {
			setSelectedTags(['All cases']);
			return;
		}

		if (selectedTags.includes(tag)) {
			const newSelectedTags = selectedTags.filter((selectedTag) => selectedTag !== tag);
			setSelectedTags(newSelectedTags.length === 0 ? ['All cases'] : newSelectedTags);
		} else {
			setSelectedTags(prev => [...prev.filter(t => t !== 'All cases'), tag]);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.has('tags')) {
			const urlTags = params.get('tags');
			if (urlTags !== null) {
				setSelectedTags([...urlTags.split(',')]);
				const container = document.getElementById('grid');
				if(container !== null) container.scrollIntoView();
			}
		}
	}, []);

	return (
		<div id="grid" className='grid'>
			<div className='grid__tabs'>
				{tags.map((tag) => (
					<div
						onClick={() => selectTag(tag || '')}
						className={clsx('grid__tabs__tab', {'active-tab': selectedTags.includes(tag)})}
						key={tag}
					>
						{tag}
					</div>
				))}
			</div>
			<div className='grid__cases'>
				{columns
					.filter(ce => selectedTags.includes('All cases') || ce.tag.some((tag) => selectedTags.includes(tag)))
					.map((caseElement) => (
						<CaseThumbnail
							key={caseElement._uid}
							{...caseElement}
						/>
					))}
			</div>
		</div>
	);
};
