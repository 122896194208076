import React from 'react';
import Picture from './picture';
import Video from './video';
import { ICase } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import { Transition } from './transition';

export  const CaseThumbnail = ({
	transition_color,
	desktop_image,
	mobile_image,
	video_mobile,
	video_desktop,
	title,
	description,
	body,
	_uid,
	className,
	link,
}: ICase) => {
	const { isDesktop } = useDesktopView();

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 297, width: 431, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 250, width: 363, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 217, width: 315, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 174, width: 252, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 126, width: 183, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 248, width: 361, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 205, width: 297, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 110, width: 160, media: '(max-width: 480px)' },
	];
	return (
		<div className={`case-thumbnail ${className}`} key={_uid}>
			<Transition transition_color={transition_color} url={link !== undefined ? link.cached_url : ''}>
				<div className='case-thumbnail__media'>
					{isDesktop && (video_desktop?.filename ? <Video className='case-thumbnail__video' video={video_desktop} poster={desktop_image?.filename} />
						: (desktop_image?.filename && <Picture className='case-thumbnail__picture' image={desktop_image} queries={queries} />))
					}
					{!isDesktop && (video_mobile?.filename ? <Video className='case-thumbnail__video' video={video_mobile} poster={mobile_image?.filename}/>
						: (mobile_image?.filename && <Picture className='case-thumbnail__picture' image={mobile_image} queries={queries} />))
					}
				</div>
				<div className='case-thumbnail__content'>
					<div className='case-thumbnail__elements'>
						<p className='case-thumbnail__headline'>{title}</p>
						<p className='case-thumbnail__description'>{description}</p>
						<p className='case-thumbnail__body'>{body}</p>
					</div>
				</div>
			</Transition>		
		</div>
	);
};
export default CaseThumbnail;
