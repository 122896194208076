import React from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../hooks/use-window-size';

type VimeoVideoProps = {
	url: string,
	className?:string,
	videoClasses: string,
}

const VimeoVideo = ({ url, className, videoClasses= '' }: VimeoVideoProps) => {
	const { width, height } = useWindowSize();
	return (
		<div className={clsx('video vimeo', className)}>
			<video className={clsx('vimeo', videoClasses)} width={width} height={height} playsInline autoPlay muted loop src={url} />
		</div>
	);
};

export default VimeoVideo;
