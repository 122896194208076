import React from 'react';
import Helmet from 'react-helmet';
// import { useStaticQuery, graphql } from 'gatsby';

type MetaProps = {
	title?:string;
	language?:string;
	description?:string;
	keywords?:string[];
	ogTitle?:string;
	ogDescription?:string;
	ogImage?:string;
	ogUrl?:string;
	ogType?:string;
}

const Meta = ({
	title,
	language,
	description,
	keywords,
	ogTitle,
	ogDescription,
	ogImage,
	ogUrl,
	ogType,
}: MetaProps) => {

	const { globalContent: { metaData: data } } = { globalContent: { metaData: null } };

	// const { site } = useStaticQuery(
	// graphql`
	//    query {
	// 	 site {
	// 	   siteMetadata {
	// 		 title
	// 		 description
	// 	   }
	// 	 }
	//    }
	//  `,
	// );

	const lang = language || data?.lang || 'en';
	const metaTitle = title || data?.documentTitle || '';
	const metaDescription = description || data?.description || '';
	const metaKeywords = keywords || data?.keywords || [];
	const openGraphTitle = ogTitle || data?.ogTitle || data?.documentTitle || '';
	const openGraphDescription = ogDescription || data?.ogDescription || '';
	const openGraphImage = ogImage || data?.ogImage || '';
	const openGraphUrl = ogUrl || data?.ogUrl || '';
	const openGraphType = ogType || data?.ogType || '';
	const defaultTitle = data?.documentTitle || '';

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			bodyAttributes={{
				class: 'body',
			}}
			title={metaTitle}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					name: 'keywords',
					content: metaKeywords.join(', '),
				},
				{
					property: 'og:title',
					content: openGraphTitle,
				},
				{
					property: 'og:description',
					content: openGraphDescription,
				},
				{
					property: 'og:type',
					content: openGraphType || 'website',
				},
				{
					property: 'og:image',
					content: openGraphImage,
				},
				{
					property: 'og:url',
					content: openGraphUrl,
				},
			]}
		/>
	);
};
export default Meta;
