import React from 'react';
import Picture from './picture';
import Video  from './video';
import { IComponent, IMobilecaseAndMedia } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';

export  const MobileCaseAndMediaColumns = ({blok: {media, mobile_case_left}}:  IComponent<IMobilecaseAndMedia>) => {
	const { isDesktop } = useDesktopView();
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 503, width: 1186, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 789, width: 1861, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 687, width: 1621, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 555, width: 1308, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 409, width: 965, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 301, width: 710, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 805, width: 606, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 560, width: 445, media: '(max-width: 480px)' },
	];
	
	return (
		<div className="mobile-case-and-media">
			{isDesktop && 
				<div className='mobile-case-and-media__container'>
					<div className={`mobile-case-and-media__container${mobile_case_left ? '--media-left-offset': '--media-right-offset'}`}>
						{media[0].video_desktop ? <div className='video'><Video className='video-loaded' video={media[0].video_desktop} poster={media[0].desktop_image?.filename} />
						</div> : <div className='image'><Picture className='image-loaded' image={media[0].desktop_image} queries={queries}/></div>}
					</div>
					<div className={`mobile-case-and-media__container${mobile_case_left ? '--media-left-offset': '--media-right-offset'}`}>
						{media[1].video_desktop ? <div className='video'> <Video className='video-loaded' video={media[1].video_desktop} poster={media[1].desktop_image?.filename}/>
						</div> : <div className='image'> <Picture className='image-loaded' image={media[1].desktop_image} queries={queries}/></div>}
					</div>
				</div> }
			{!isDesktop && 
				<div className='mobile-case-and-media__container'>
					<div className={`mobile-case-and-media__container${mobile_case_left ? '--media-left-offset': '--media-right-offset'}`}>
						{media[0].video_mobile ? <div className='video'> <Video className='video-loaded' video={media[0].video_mobile} poster={media[0].mobile_image?.filename}/>
						</div> :<div className='image'><Picture className='image-loaded' image={media[0].mobile_image} queries={queries}/></div> }
					</div>
					<div className={`mobile-case-and-media__container${mobile_case_left ? '--media-left-offset': '--media-right-offset'}`}>
						{media[1].video_mobile ? <div className='video'> <Video className='video-loaded' video={media[1].video_mobile} poster={media[1].mobile_image?.filename}/>
						</div> : <div className='image'><Picture className='image-loaded' image={media[1].mobile_image} queries={queries}/> </div>}
					</div>
				</div>}
		</div>
	);
};
export default MobileCaseAndMediaColumns;
