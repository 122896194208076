import React from 'react';

const Logo = ({ ...args }) => (
	<svg {...args} id="Logo" xmlns="http://www.w3.org/2000/svg" width="87" height="9.707" viewBox="0 0 87 9.707">
		<path id="Path_4818" data-name="Path 4818" d="M34.358,12.589a3.248,3.248,0,0,0-3.368,3.34,3.282,3.282,0,0,0,3.368,3.382A3.451,3.451,0,0,0,37.3,18L35.9,17.047a1.782,1.782,0,0,1-1.408.607c-.676,0-1.491-.331-1.587-1.077h4.721c.014-.166.028-.428.028-.594A3.246,3.246,0,0,0,34.358,12.589Zm-1.422,2.788a1.41,1.41,0,0,1,1.435-1.1,1.307,1.307,0,0,1,1.353,1.1Z" transform="translate(-23.701 -9.628)"/>
		<rect id="Rectangle_81" data-name="Rectangle 81" width="1.919" height="9.5" transform="translate(14.992 0.045)"/>
		<path id="Path_4819" data-name="Path 4819" d="M308.333,12.589a3.248,3.248,0,0,0-3.368,3.34,3.282,3.282,0,0,0,3.368,3.382A3.45,3.45,0,0,0,311.273,18l-1.394-.952a1.782,1.782,0,0,1-1.408.607c-.676,0-1.491-.331-1.587-1.077h4.72c.014-.166.028-.428.028-.594A3.246,3.246,0,0,0,308.333,12.589Zm-1.422,2.788a1.41,1.41,0,0,1,1.435-1.1,1.306,1.306,0,0,1,1.353,1.1Z" transform="translate(-233.235 -9.628)"/>
		<rect id="Rectangle_82" data-name="Rectangle 82" width="1.919" height="9.5" transform="translate(79.433 0.045)"/>
		<rect id="Rectangle_83" data-name="Rectangle 83" width="1.919" height="9.5" transform="translate(18.402 0.045)"/>
		<path id="Path_4820" data-name="Path 4820" d="M94.428,12.589a3.241,3.241,0,0,0-3.34,3.34,3.347,3.347,0,1,0,6.694,0A3.252,3.252,0,0,0,94.428,12.589Zm0,4.817a1.47,1.47,0,1,1,1.463-1.477A1.422,1.422,0,0,1,94.428,17.406Z" transform="translate(-69.663 -9.628)"/>
		<path id="Path_4821" data-name="Path 4821" d="M128.791,13.176l-1.146,3.837-1.132-3.837h-1.643l-1.132,3.837-1.146-3.837h-2.043l2.333,6.446h1.6l1.215-4.1,1.27,4.1h1.6l2.264-6.446Z" transform="translate(-92.197 -10.077)"/>
		<g id="Group_235" data-name="Group 235" transform="translate(0 0.045)">
			<path id="Path_4822" data-name="Path 4822" d="M3.51,5.994v3.7H5.574v-3.7L2.261.19H0Z" transform="translate(0 -0.19)"/>
			<path id="Path_4823" data-name="Path 4823" d="M21.38.19,19.139,4.115h2.128L23.641.19Z" transform="translate(-14.638 -0.19)"/>
		</g>
		<path id="Path_4824" data-name="Path 4824" d="M182.178,10.784V6.04H184.2v4.744a3.673,3.673,0,0,1-7.341,0V6.04h2.048v4.744a1.636,1.636,0,0,0,3.272,0Z" transform="translate(-135.26 -4.619)"/>
		<path id="Path_4825" data-name="Path 4825" d="M220.356,15.805v3.417h-1.911V15.736a1.116,1.116,0,0,0-1.059-1.245c-.577,0-1.113.332-1.113,1.314v3.417h-1.911V12.762h1.911V13.7a1.836,1.836,0,0,1,1.677-1.065,2.073,2.073,0,0,1,2.062,1.134,2.2,2.2,0,0,1,1.925-1.148c1.526,0,2.474.982,2.474,2.933v3.666h-1.9V15.778c0-.844-.495-1.287-1.058-1.287C220.85,14.491,220.356,14.864,220.356,15.805Z" transform="translate(-163.942 -9.654)"/>
		<path id="Path_4826" data-name="Path 4826" d="M267.8,19.673H266.2l-1.265-4.109-1.21,4.109h-1.594l-2.323-6.461h2.034l1.141,3.846,1.127-3.846h1.636l1.127,3.846,1.141-3.846h2.035Z" transform="translate(-198.702 -10.104)"/>
		<path id="Path_4827" data-name="Path 4827" d="M354.652,3.108V4.97h-1.483v4.6h-1.9V4.97h-1.3V3.108h1.3V0h1.9V3.108Z" transform="translate(-267.652)"/>
	</svg>
);
export default Logo;
