import React from 'react';
import { IComponent, ICompanyContact } from '../types/blok';

export const Contact = ({ blok: { contact_columns }}: IComponent<ICompanyContact>) => {
	return (
		<div className='contact'>
			<div className='contact__wrapper'>	
				{contact_columns && contact_columns.map(column => <div className='contact__content'
					key={column.title}>
					<div className='contact__title'>{column.title}</div>
					<div className='contact__body'>
						<div className='contact__elements'><a href={`mailto:${column.email}`}>{column.email}</a></div>
						<a href={`tel:${column.telephone}`} className='contact__elements'>{column.telephone}</a>
						<div className='contact__elements'>{column.address}</div>
					</div> 
				</div>)}
			</div>	
		</div>
	);
};
