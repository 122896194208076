import React from 'react';
import Picture from './picture';
import { IComponent, IMediaText } from '../types/blok';
import Video  from './video';

export  const MediaWithText = ({blok: {image, video, description, title}}:  IComponent<IMediaText>) => {
	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 503, width: 1186, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 789, width: 1861, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 687, width: 1621, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 555, width: 1308, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 409, width: 965, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 301, width: 710, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 805, width: 606, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 560, width: 445, media: '(max-width: 480px)' },
	];

	return (
		<div className="media-with-text">
			<div className='media-with-text__media-wrapper'>
				{video.filename ? <Video className='media-with-text__video' video={video} poster={image?.filename}/>
					: <Picture className='media-with-text__image' image={image} queries={queries}/>}
			</div>
			<div className='media-with-text__content'>
				<div className='media-with-text__elements'>
					<h1 className='media-with-text__title'>{title}</h1>
					<p className='media-with-text__description'>{description}</p>
				</div>
			</div><></>
		</div>
	);
};
export default MediaWithText;
