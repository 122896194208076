import React from 'react';
import { IComponent, IAwards} from '../types/blok';

export const Awards = ({blok: { awards, title } }:  IComponent<IAwards>) => {
	return(
		<div className='awards'>
			<div className='awards__title'>{title}</div>
			{ awards && awards.map( a => <div className='awards__wrapper' key={a._uid}>	
				<div className='awards__elements-container'>
					<div className='awards__element'><span className='awards__organization'>{a.organization}</span></div>
					<div className='awards__element'><span className='awards__name'>{a.award_name}</span></div>
				</div>
				<div className='awards__elements-container'>
					<div className='awards__element'>
						<div className={`awards__element-award awards__element-award--${a.award}`}></div>
					</div>
					<div className='awards__element awards__year'>{a.year}</div>
				</div>
			</div>) }
		</div>
	);
};
