import React, { useEffect, useState, useRef } from 'react';
import Picture from './picture';
import Video from './video';
import { IHeroCaseLink, IComponent } from '../types/blok';
import { useDesktopView } from '../hooks/use-desktop-view';
import { Transition } from './transition';

export  const HeroCaseLink = ({blok: {transition_color, case_link, desktop_image,mobile_image, client, solution, description, title, subtitle, video_mobile, video_desktop }}:  IComponent<IHeroCaseLink>) => {

	const [isVisible, setIsvisible] = useState(false);
	const { isDesktop } = useDesktopView();
	const containerRef = useRef<HTMLDivElement>(null);

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1057, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 906, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 714, width: 1367, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 635, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 635, width: 770, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 635, width: 642, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 635, width: 480, media: '(max-width: 480px)' },
	];

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: .01,
	};

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		setIsvisible(entries.some(el => el.isIntersecting));
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, options);
		if(containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => { 
			if(containerRef.current) {
				observer.unobserve(containerRef.current);
			}
		};
	}, [containerRef]);

	const heroCaseMedia = <div className='hero-case-link__media hero-case-link__media--bottom'>
		<div className='hero-case-link__media-overlay'></div>
		<div className='hero-case-link__content hero-case-link__content--bottom'>
			<div className='hero-case-link__subtitle'>{subtitle}</div>
			<div className='hero-case-link__elements'>
				<div className='hero-case-link__client'>{client}</div>
				<div className='hero-case-link__solution'>{solution}</div>
			</div>
			<div className='hero-case-link__description'>{description}</div>				
		</div>
		{isDesktop && (video_desktop?.filename ? <div> 
			<Video className='hero-case-link__video' video={video_desktop} poster={desktop_image?.filename}/>
		</div> : <Picture className='hero-case-link__picture' image={desktop_image} queries={queries}/>)}
		{!isDesktop && 
			(video_mobile?.filename ? <Video className='hero-case-link__video' video={video_mobile} poster={mobile_image?.filename}/>
				: <Picture className='hero-case-link__picture' image={mobile_image} queries={queries}/>)}		
	</div>;

	return (
		<div className='hero-case-link__wrapper'>
			<div className='hero-case-link' ref={containerRef} style={{ opacity: isVisible ? 1 : 0 }}>
				<div className='hero-case-link__heading hero-case-link__heading--bottom'>
					<div className='hero-case-link__headline'>
						<h1 className='hero-case-link__title'>{title}</h1>
					</div>
				</div>
				<Transition transition_color={transition_color} url={case_link[0]?.url.cached_url}>
					{heroCaseMedia}
				</Transition>
			</div>
		</div>
	);
};
export default HeroCaseLink;
